body {
  background-color: #1a1625;
  color: #ffffff;
}

.ada-price-tracker {
  margin-top: 2rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  display: inline-block;

  h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #08021a;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.1rem;

    &.price-up {
      color: #4caf50;
    }

    &.price-down {
      color: #f44336;
    }
  }
}

.home-main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }

  .home-head {
    text-align: center;
    margin-bottom: 3rem;
    padding: 2rem 0;

    @media (max-width: 768px) {
      margin-bottom: 2rem;
      padding: 1rem 0;
    }

    h3 {
      color: #08021a;
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 2rem;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

      @media (max-width: 768px) {
        font-size: 1rem;
        margin-bottom: 1.5rem;
        padding: 0 1rem;
      }
    }
  
    h1 {
      font-size: 3.5rem;
      font-weight: 800;
      margin-bottom: 1.5rem;
      letter-spacing: -0.5px;
      line-height: 1.2;
      background: linear-gradient(135deg, #0033AD 0%, #0055FF 50%, #0033AD 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;

      @media (max-width: 768px) {
        font-size: 2rem;
        margin-bottom: 1rem;
        padding: 0 0.5rem;
      }
  
      &:hover {
        transform: scale(1.02);
      }
    }
  }
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 3rem auto;
  padding: 0 2rem;
  max-width: 1200px;

  .service-card {
    background: #ffffff;
    border-radius: 12px;
    padding: 2rem;
    text-align: center;
    transition: transform 0.3s ease;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    &:hover {
      transform: translateY(-5px);
    }

    .service-icon-wrapper {
      width: 60px;
      height: 60px;
      background: #f5f5f5;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 1.5rem;

      .service-icon {
        font-size: 1.5rem;
        color: #0033AD;
      }
    }

    h3 {
      color: #333333;
      font-size: 1.25rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    p {
      color: #666666;
      font-size: 0.9rem;
      line-height: 1.5;
    }
  }
}

.trending-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem 0;

  .trending-card {
    background: linear-gradient(135deg, rgba(45, 42, 61, 0.8) 0%, rgba(45, 42, 61, 0.6) 100%);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(138, 43, 226, 0.1);
    border-radius: 12px;
    padding: 1.5rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 12px;
      padding: 1px;
      background: linear-gradient(45deg, #8a2be2, #4a90e2);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 6px 15px rgba(138, 43, 226, 0.15);

      &::before {
        animation: borderGlow 1.5s linear infinite;
      }
    }

    h3 {
      font-size: 1.2rem;
      color: #ffffff;
      margin-bottom: 0.8rem;
      font-weight: 600;
    }

    p {
      color: #b8b8b8;
      margin-bottom: 0.5rem;
      font-size: 0.9rem;

      &.change.positive {
        color: #00ff9d;
      }
    }
  }
}

@keyframes borderGlow {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
  .home-foot {
    text-align: center;
    margin-top: 3rem;
    
    h4 {
      color: #b8b8b8;
      
      span a {
        color: #8a2be2;
        text-decoration: underline;
        
        &:hover {
          color: #4b0082;
        }
      }
    }
  }

.home-footer-main {
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  display: flex;
  justify-content: center;
  gap: 3rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  div {
    display: flex;
    align-items: center;
    color: #b8b8b8;
    transition: color 0.3s ease;

    &:hover {
      color: #8a2be2;
    }

    .foot-icon {
      font-size: 1.2rem;
      margin-right: 0.5rem;
    }
  }
}

.popup-wallet-name {
  background: #2d2a3d;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1.5rem;

  .wallet-name1 {
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .wallet-name2 {
    color: #b8b8b8;
    font-size: 0.9rem;

    span {
      color: #8a2be2;
    }
  }
}

.chakra-modal__content {
  background: #1a1625 !important;
  color: #ffffff !important;
}

.chakra-modal__close-btn {
  color: #ffffff !important;
}

.chakra-modal__header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.img-card-main {
    text-align: center;
    padding: 25px;

    img {
        width: 100%;
        border-radius: 50%;
    }

    &:hover {
        cursor: pointer;
    }
}

.trending-section {
  margin-top: 3rem;

  h2 {
    color: #1a1625;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
    text-align: center;
  }

  .trending-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 3rem;

    .trending-card {
      background: linear-gradient(145deg, #2d2a3d, #1a1625);
      border-radius: 15px;
      padding: 1.5rem;
      position: relative;
      overflow: hidden;
      transition: all 0.3s ease;
      border: 1px solid rgba(138, 43, 226, 0.1);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 15px;
        padding: 2px;
        background: linear-gradient(45deg, #8a2be2, #4b0082);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        opacity: 0.5;
        transition: opacity 0.3s ease;
      }

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 20px rgba(138, 43, 226, 0.15);

        &::before {
          opacity: 1;
        }
      }

      h3 {
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }

      p {
        color: #b8b8b8;
        font-size: 1rem;
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.change {
          color: #00ff9d;
          font-weight: 600;
          font-size: 1.1rem;
        }
      }
    }
  }
}

.latest-news {
    margin: 4rem 0;

    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
      color: #ffffff;
      text-align: center;
    }

    .news-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 2rem;

      .news-card {
        background: rgba(255, 255, 255, 0.05);
        padding: 1.5rem;
        border-radius: 12px;
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-5px);
          background: rgba(255, 255, 255, 0.1);
        }

        h3 {
          font-size: 1.3rem;
          color: #08021a;
          margin-bottom: 1rem;
        }

        p {
          color: #545252;
          margin-bottom: 0.5rem;

          &.date {
            font-size: 0.9rem;
            color: #8a2be2;
          }
        }
      }
    }
  }

.trending-projects {
  margin: 4rem 0;
  
  h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #08021a;
  }

  .project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }

  .project-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 1.5rem;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      background: rgba(255, 255, 255, 0.1);
    }

    h3 {
      font-size: 1.3rem;
      font-weight: bold;
      color: #2f0240;
      margin-bottom: 0.5rem;
    }

    p {
      color: #626060;
      margin-bottom: 1rem;
    }

    .growth {
      display: inline-block;
      padding: 0.3rem 0.8rem;
      border-radius: 20px;
      background: rgba(76, 175, 80, 0.1);
      color: #4caf50;
      font-weight: 500;
    }
  }
}

.footer {
    text-align: center;
    margin-top: 4rem;
    padding-top: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    .social-links {
      margin-bottom: 1rem;

      .social-icon {
        font-size: 1.5rem;
        color: #ffffff;
        margin: 0 1rem;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
          color: #8a2be2;
        }
      }
    }

    p {
      color: #b8b8b8;
    }
}

footer {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;

    div {
        width: 150px;
        display: flex;
        text-align: center;
        justify-content: center;

        .foot-icon {
            font-size: 20px;
            color: #4a4a4a;
            margin-right: 10px;
        }
    }
}

.chakra-modal__content-container {
    align-items:center!important;
}



.popup-wallet-name {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px 20px;
    background: #2d2a3d;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;

    .wallet-name1 {
        font-weight: bold;
        color: #ffffff;
    }

    .wallet-name2 {
        font-size: 13px;
        margin: 7px 0px;
        color: #b8b8b8;
    }
}
.wallet-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 1rem;
  padding: 1rem;

  .wallet-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background: #2d2a3d;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.2s ease;

    &:hover {
      transform: translateY(-5px);
      border-color: #8a2be2;
    }

    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-bottom: 0.5rem;
      object-fit: contain;
    }

    p {
      color: #ffffff;
      font-size: 0.9rem;
      text-align: center;
      margin: 0;
    }
  }
}

.market-analysis {
  margin: 4rem 0;

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #ffffff;
    text-align: center;
  }

  .metrics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;

    .metric-card {
      background: rgba(255, 255, 255, 0.05);
      padding: 1.5rem;
      border-radius: 12px;
      text-align: center;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
        background: rgba(255, 255, 255, 0.1);
      }

      h3 {
        font-size: 1.2rem;
        color: #08021a;
        margin-bottom: 1rem;
      }

      .value {
        font-size: 1.8rem;
        color: #8a2be2;
        margin-bottom: 0.5rem;
      }

      .trend-up {
        color: #4caf50;
      }

      .trend-down {
        color: #f44336;
      }
    }
  }
}

.ecosystem-stats {
  margin: 4rem 0;

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #ffffff;
    text-align: center;
  }

  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;

    .stat-card {
      background: rgba(255, 255, 255, 0.05);
      padding: 1.5rem;
      border-radius: 12px;
      text-align: center;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
        background: rgba(255, 255, 255, 0.1);
      }

      h3 {
        font-size: 1.2rem;
        color: #08021a;
        margin-bottom: 1rem;
      }

      .count {
        font-size: 1.8rem;
        color: #8a2be2;
        margin-bottom: 0.5rem;
      }

      .growth {
        color: #4caf50;
      }
    }
  }
}

.dev-resources {
  margin: 4rem 0;

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #ffffff;
    text-align: center;
  }

  .resources-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;

    .resource-card {
      background: rgba(255, 255, 255, 0.05);
      padding: 1.5rem;
      border-radius: 12px;
      text-align: center;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
        background: rgba(255, 255, 255, 0.1);
      }

      h3 {
        font-size: 1.2rem;
        color: #08021a;
        margin-bottom: 1rem;
      }

      .resource-link {
        display: inline-block;
        padding: 0.5rem 1rem;
        background: #8a2be2;
        color: #ffffff;
        border-radius: 6px;
        text-decoration: none;
        transition: background 0.3s ease;

        &:hover {
          background: #9400d3;
        }

        &.docs { background: #4caf50; }
        &.code { background: #2196f3; }
        &.community { background: #ff9800; }
      }
    }
  }
}