.upload {
    textarea{
        background-color: rgba(254, 249, 249, 0.448);
        height: 150px;
        width: 100%;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid rgb(206, 206, 206);
    }

    input {
        width: 100%;
        height: 40px;
        border: 1px solid gray;
        border-radius: 3px;
        padding: 10px;
        margin-top: 15px;
    }
}

.submit-btn-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin-top: 20px;

    button {
        padding: 7px 20px;
    background-color: #2997d8;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 3px;
    width: 60%;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.5s;
    }
}