.error-home-main {
  margin: 0 auto;
  height: 100%;
  padding-top: 100px;
  max-width: 1200px;

  @media (min-height:1100px) {
    height: 100vh;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      height: 200px;
    }

    h1 {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 35px;
      font-weight: 500;

      @media (max-width:500px) {
        font-size: 25px;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.35;
    }

    button {
        background-color: purple;
        font-size: .875rem;
        border-radius: 0.375rem;
        padding-right: 3rem;
        padding-left: 3rem;
        padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border: none;
    color: white;
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: 500;
    transition: .5s;

    &:hover {
        background-color: rgb(113, 3, 113);
        cursor: pointer;
    }
    }
  }
}

.errror-foot {
  //position: absolute;
  width: 100%;
  bottom: 0;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #e2e8f0;
  //margin: 0 auto;
  //max-width: 1200px;

  @media (max-width:500px) {
    height: 100px;
  }

  @media (min-height:1100px) {
    position: absolute;
  }

  .error-icon {
    display: flex;
    margin-bottom: 20px;

    span {
      font-size: 20px;
      padding: 5px;
      background-color: rgb(222, 221, 221);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
    }
  }
}
